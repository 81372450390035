import { useState, useRef, useEffect, useCallback } from 'react';
import { useGetFilteredFinancialsData } from './FinancialTables.hooks.ts';
import { FinancialFilter } from '../Header/FinancialsHeader.hooks.ts';
import { Box, Skeleton, VStack, Spinner, HStack } from '@chakra-ui/react';
import { BusinessUnitFinancialTable } from './BusinessUnitFinancialTable.tsx';
import { GroupFinancials } from './GroupFinancials.tsx';
import { CompanyFinancialResults } from 'Features/Financials/Financials.hooks.ts';
import { CompanyNotEligibleTable } from './CompanyNotEligibleTable.tsx';
import { FinancialSummary } from './SummaryTable.tsx';
import { TotalComparisonTable } from './TotalComparisonTable.tsx';
import { Typography } from 'Tokens/Typography/Typography.tsx';

const useInfiniteLoading = (itemsCount: number, itemsPerLoad = 5) => {
  const [loadedCount, setLoadedCount] = useState(itemsPerLoad);
  const [isFetching, setIsFetching] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!loadMoreRef.current) return;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetching) {
          setIsFetching(true);
          setTimeout(() => {
            setLoadedCount((prev) => Math.min(prev + itemsPerLoad, itemsCount));
            setIsFetching(false);
          }, 1000);
        }
      },
      { threshold: 1.0 }
    );

    observerRef.current.observe(loadMoreRef.current);
    return () => observerRef.current?.disconnect();
  }, [itemsCount, isFetching, itemsPerLoad]);

  return {
    loadedCount,
    isFetching,
    loadMoreRef,
  };
};

export const FilteredFinancials = () => {
  const { filter, filteredFinancials, loading, sortedItems } = useGetFilteredFinancialsData();

  const tableComponents = sortedItems.map((item) =>
    item.isGroup ? (
      <GroupFinancials key={item.id} reportingGroupId={item.id} bg="solid" />
    ) : (
      <BusinessUnitFinancialTable
        key={item.id}
        businessUnit={item.data as CompanyFinancialResults['businessUnits'][number]}
      />
    )
  );

  const staticTables = [
    <Box key="not-eligible" width="100%">
      <CompanyNotEligibleTable />
    </Box>,
    <Box key="summary" width="100%">
      <FinancialSummary />
    </Box>,
    <Box key="comparison" width="100%">
      <TotalComparisonTable />
    </Box>,
  ];

  const { loadedCount, isFetching, loadMoreRef } = useInfiniteLoading(
    tableComponents.length + staticTables.length
  );

  if (filter === FinancialFilter.byId && filteredFinancials) {
    return (
      <Skeleton isLoaded={!loading}>
        <BusinessUnitFinancialTable businessUnit={filteredFinancials} />
      </Skeleton>
    );
  }

  return (
    <Skeleton isLoaded={!loading} width="100%" padding="16px" borderRadius="16px">
      <VStack spacing="16px" paddingBottom="16px" width="100%" alignItems="flex-start">
        {tableComponents.slice(0, loadedCount)}
        {staticTables.slice(0, Math.max(0, loadedCount - tableComponents.length))}

        {loadedCount < tableComponents.length + staticTables.length && (
          <Box ref={loadMoreRef} width="100%" display="flex" justifyContent="center" padding="16px">
            {isFetching && (
              <HStack>
                <Typography variant="detail">Loading more tables...</Typography>
                <Spinner size="md" />
              </HStack>
            )}
          </Box>
        )}
      </VStack>
    </Skeleton>
  );
};
