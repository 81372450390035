import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { sortBy, uniq, uniqBy } from 'lodash';
import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetAssessmentCurrencyConversionQuery_,
  GetMetricsDrQuery_,
  GetRequiredMaterialMetricsQuery_,
  QuestionType_Enum_,
  useEsrsAssessmentProjectLeaderQuery,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetAnswersForMetricsOnGroupLevelQuery,
  useGetAssessmentCurrencyConversionQuery,
  useGetMetricsDrQuery,
  useGetRequiredMaterialMetricsQuery,
} from 'models';
import { TableData } from 'Molecules/NestedTable';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import {
  FrequencyEnums,
  QuartersObject,
  QUARTERS_FIELDS,
  TimePeriodsEnums,
  YearObject,
} from '../../Requirement';
import {
  convertCurrency,
  getNestedRows,
  getTagsCombination,
  getUniqueDataPoints,
  MetricsTableData,
} from '../MetricAnswers.hooks';
import {
  filterAggregatedChildrenInFirstLevelMetrics,
  filterChildrenInFirstLevelMetrics,
  separateQualitativeMetricsFromQuantitativeParents,
} from '../Metrics.utils';
import {
  combinedFiltersForNoTags,
  combinedFiltersForTags,
  resolveMetricCalculation,
} from './calculations';
import { AssessableMetrics } from '../Metrics';
import { BooleanEnum } from 'Molecules';
import { GroupMaterialMetrics } from './AggregatedMetricsUtils';

export type MetricType = NonNullable<
  GetMetricsDrQuery_['DisclosureRequirement_by_pk']
>['metrics'][number];

export type PartialMetricType = Omit<MetricType, 'adminPanelTags' | 'childrenMetrics'> & {
  adminPanelTags?: MetricType['adminPanelTags'];
  childrenMetrics: { childMetric?: PartialMetricType | null }[];
};

type MaterialMetric = MetricType['materialMetrics'][number];

type Tags = {
  tagType: string;
  tagValue: string;
}[];

export type AggregatedMetricsExtraData = {
  metric: MetricType;
  tags?: Tags;
  tagName?: string;
  tagType?: string;
  referenceToSource?: string;
  isChild?: boolean;
  result?: YearObject;
  percentage?: string;
  reportingUnitId?: string;
  unlockCondition?: boolean | null;
};

export type AggregatedMetricsTableData = TableData<AggregatedMetricsExtraData>;

type AnswerMetricsQueryType = NonNullable<
  GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
>;
export type SubsidiaryAssessmentsType = AnswerMetricsQueryType['subsidiaryAssessments'][number];
export type ReportingUnitType =
  AnswerMetricsQueryType['subsidiaryAssessments'][number]['reportingUnits'][number];
export type MetricAnswer =
  AnswerMetricsQueryType['subsidiaryAssessments'][number]['reportingUnits'][number]['answers'][number] & {
    frequency?: string;
    hasTags?: boolean;
    materialMetricTags?: MaterialMetric['materialMetricTags'];
  };
export type DataPoint = MetricAnswer['datapoints'][number];
export type AggregatedQualitativeAnswers = {
  metricRef?: string;
  answer: MetricAnswer | undefined;
  reportingUnits?: {
    reportingUnit: ReportingUnitType;
    answer: MetricAnswer | undefined;
  }[];
  subsidiaries?: {
    subsidiary: SubsidiaryAssessmentsType;
    answer: MetricAnswer | undefined;
    reportingUnits?: {
      reportingUnit: ReportingUnitType;
      answer: MetricAnswer | undefined;
    }[];
  }[];
}[];
type TagType = {
  isOptional: boolean;
  tagType: string;
  valueOptions: {
    tagValue: string;
  }[];
};

type PartialAssessableMetric = Omit<AssessableMetrics[number], 'childrenMetrics'>;
interface MaybeHasChild extends PartialAssessableMetric {
  childrenMetrics?: { childMetric?: MaybeHasChild | null }[];
}

const addQuarters = (answers: QuartersObject) => {
  const sum = Object.values(answers)
    ?.filter((value) => !isNaN(value))
    ?.reduce((a, b) => Number(a ?? 0) + Number(b ?? 0), 0);
  return sum;
};

const mapMetricsWithAnswers = ({
  metrics,
  isGroup,
  companyAnswersData,
  groupAnswersData,
  companyStandardId,
}: {
  metrics: AssessableMetrics;
  isGroup: boolean;
  companyAnswersData: NonNullable<
    GetAnswersForMetricsOnCompanyLevelQuery_['EsrsAssessment_by_pk']
  >['reportingUnits'];
  groupAnswersData: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'];
  companyStandardId: string;
}) => {
  return metrics.map((metric) => {
    if (isGroup) {
      const isGroupLevel =
        metric.materialMetrics?.[0]?.dataCollection === DataCollectionLevel.group;

      if (isGroupLevel) {
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
        };
      } else
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
          subsidiaries: groupAnswersData.flatMap((subsidiary) => {
            return {
              subsidiary: subsidiary,
              answer: subsidiary.reportingUnits
                .find((ru) => ru.isCompanyLevel)
                ?.answers?.find((answer) => answer.metricRef === metric.reference),
              reportingUnits: subsidiary.reportingUnits
                .filter((ru) => !ru.isCompanyLevel)
                ?.flatMap((ru) => {
                  return {
                    reportingUnit: ru,
                    answer: ru.answers?.find((answer) => answer.metricRef === metric.reference),
                  };
                }),
            };
          }),
        };
    } else {
      const isCompanyLevel =
        metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
          ?.dataCollection === DataCollectionLevel.company;

      if (isCompanyLevel) {
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
          reportingUnits: companyAnswersData
            .filter((ru) => !ru.isCompanyLevel)
            .flatMap((ru) => {
              return {
                reportingUnit: ru,
                answer: ru.answers?.find((answer) => answer.metricRef === metric.reference),
              };
            }),
        };
      } else
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
          reportingUnits: companyAnswersData
            .filter((ru) => !ru.isCompanyLevel)
            .flatMap((ru) => {
              return {
                reportingUnit: ru,
                answer: ru.answers?.find((answer) => answer.metricRef === metric.reference),
              };
            }),
        };
    }
  });
};

export const useGetAggregatedMetricsData = (withAssociation = false, noFilter = false) => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { companyType, loading: loadingType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: assessmentData, loading: assessmentLoading } = useEsrsAssessmentProjectLeaderQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const projectLeader = useMemo(
    () => assessmentData?.esrsAssessment?.projectLeader ?? undefined,
    [assessmentData]
  );

  const { data: requiredMaterialMetricsData, loading: requiredmmLoading } =
    useGetRequiredMaterialMetricsQuery({
      variables: {
        disclosureRequirementRef: disclosureRequirementRef,
        materialStandardId: companyAssessmentId,
        parentStandardId: parentAssessmentId || companyAssessmentId,
      },
      skip: !disclosureRequirementRef || !companyAssessmentId,
    });

  const requiredMetrics = useMemo(
    () =>
      uniq(requiredMaterialMetricsData?.esrs_MaterialMetric.map((mm) => mm.metric.reference)) ?? [],
    [requiredMaterialMetricsData]
  );

  const requiredMaterialMetrics = useMemo(
    () => requiredMaterialMetricsData?.esrs_MaterialMetric ?? [],
    [requiredMaterialMetricsData]
  );

  const { data: metricsDisclosureData, loading: metricsLoading } = useGetMetricsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      requiredMetrics: requiredMetrics,
      materialStandardId: companyAssessmentId,
      parentStandardId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId || !requiredMetrics.length,
  });

  const [metricDR, metrics] = useMemo(
    () => [
      metricsDisclosureData?.DisclosureRequirement_by_pk,
      metricsDisclosureData?.DisclosureRequirement_by_pk?.metrics.filter((metric) =>
        noFilter ? true : withAssociation ? metric.hasAssociation : !metric.hasAssociation
      ),
    ],
    [metricsDisclosureData, withAssociation]
  );

  const { reportingUnitAssessments } = useAssessmentReportingUnits(esrsAssessmentId);
  const companyLevelReportingUnitId = useMemo(
    () => reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
    [reportingUnitAssessments]
  );

  const { data: companyMetricAnswersData, loading: loadingCompany } =
    useGetAnswersForMetricsOnCompanyLevelQuery({
      variables: { esrsAssessmentId },
    });
  const { data: groupMetricAnswersData, loading: loadingGroup } =
    useGetAnswersForMetricsOnGroupLevelQuery({
      variables: { esrsAssessmentId },
      skip: !isGroup,
    });

  const companyAnswersData = useMemo(
    () => companyMetricAnswersData?.EsrsAssessment_by_pk?.reportingUnits ?? [],
    [companyMetricAnswersData]
  );

  const groupAnswersData = useMemo(
    () => groupMetricAnswersData?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? [],
    [groupMetricAnswersData]
  );

  return {
    metricDR,
    metrics,
    companyLevelReportingUnitId,
    companyAssessmentId,
    parentAssessmentId,
    projectLeaderId: projectLeader?.id,
    projectLeader,
    isGroup,
    requiredMaterialMetrics,
    companyAnswersData,
    groupAnswersData,
    dataLoading:
      metricsLoading ||
      materialLoading ||
      assessmentLoading ||
      loadingType ||
      requiredmmLoading ||
      loadingCompany ||
      loadingGroup,
  };
};

export const getMaterialMetricsTags = (
  groupAnswersData: AnswerMetricsQueryType['subsidiaryAssessments'],
  standardRef: string,
  metricRef: string
) => {
  const materialMetrics = groupAnswersData.flatMap((company) =>
    company.materialStandards
      .find((ms) => ms.standardRef === standardRef)
      ?.materialMetrics.find((mm) => mm?.metricRef === metricRef)
  );
  const materialMetricTagsArray =
    (materialMetrics
      ?.flatMap((mm) => mm?.materialMetricTags)
      .filter((mm) => mm !== undefined) as TagType[]) ?? [];

  const uniqueTagTypes = [...new Set(materialMetricTagsArray?.flatMap((tag) => tag.tagType))];
  const filteredUniqueTagTypes = uniqueTagTypes.filter(
    (type) =>
      materialMetricTagsArray.filter((t) => t.tagType === type).length === groupAnswersData.length
  );

  const combinedMaterialMetricValues = filteredUniqueTagTypes.map((type) => ({
    ...(materialMetricTagsArray?.find((obj) => obj.tagType === type) ?? ({} as TagType)),
    materialTagValues: sortBy(
      uniqBy(
        materialMetricTagsArray
          ?.filter((obj) => obj.tagType === type)
          ?.flatMap((tag: any) => tag.materialTagValues),
        'tagValue'
      ),
      'tagValue'
    ),
    valueOptions: sortBy(
      uniqBy(
        materialMetricTagsArray
          ?.filter((obj) => obj.tagType === type)
          .flatMap((obj) => obj.valueOptions),
        'tagValue'
      ),
      'tagValue'
    ),
  }));
  return combinedMaterialMetricValues;
};

export const addMaterialMetricTags = (
  metric: PartialMetricType,
  groupAnswersData: SubsidiaryAssessmentsType[],
  standardRef: string
) => {
  let updatedMetric = {
    ...metric,
    materialMetrics: metric.materialMetrics.map((materialMetric) => ({
      ...materialMetric,
      materialMetricTags: getMaterialMetricsTags(groupAnswersData, standardRef, metric.reference),
    })),
  };

  if (!!metric.childrenMetrics?.length) {
    const updatedChildren = metric.childrenMetrics.map(({ childMetric }) => ({
      childMetric: childMetric
        ? addMaterialMetricTags(childMetric as MetricType, groupAnswersData, standardRef)
        : childMetric,
    }));
    updatedMetric = {
      ...updatedMetric,
      childrenMetrics: updatedChildren as AssessableMetrics[number]['childrenMetrics'],
    };
  }

  return updatedMetric;
};

export const getFullMetricWithChildren = (metric: PartialMetricType) => {
  const childrenMetricRefs = [metric];

  const recurseChildren = (childrenMetrics: PartialMetricType['childrenMetrics']) => {
    if (!!childrenMetrics?.length) {
      childrenMetrics.forEach((child) => {
        if (!!child.childMetric) {
          childrenMetricRefs.push(child.childMetric as MetricType);
          recurseChildren(child?.childMetric.childrenMetrics as MetricType['childrenMetrics']);
        }
      });
    }
  };

  if (metric?.childrenMetrics) {
    recurseChildren(metric?.childrenMetrics);
  }
  return childrenMetricRefs.filter((m) => m !== undefined) ?? [];
};

const getRequiredAnswers = (
  metric: AggregatedMetricsTableData['metric'],
  answers: MetricAnswer[],
  isChild = false,
  tags?: {
    tagType: string;
    tagValue: string;
  }[][],
  childrenMetrics?: string[]
) => {
  const isParent = !!metric.childrenMetrics?.length;
  const isParentAndChild = !!metric.childrenMetrics?.length && !!metric.parentMetrics?.length;
  const hasTags = tags?.length ?? false;
  const directChildrenRefs = metric.childrenMetrics?.map((child) => child?.childMetric?.reference);

  if (isParentAndChild || (isParent && !hasTags)) {
    return answers.filter((answer) => childrenMetrics?.includes(answer.metricRef));
  }
  if (isParent && hasTags) {
    return answers
      .filter((answer) => directChildrenRefs?.includes(answer.metricRef))
      .filter((x) => x.metricRef !== metric.reference);
  }
  if (isChild || hasTags) {
    return answers.filter((answer) => answer.metricRef === metric.reference);
  }
  return answers;
};

export const hasQuarterlyFrequency = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.some(
      (child) => child.childMetric && hasQuarterlyFrequency(child.childMetric)
    );
  }
  return materialMetric?.frequency === FrequencyEnums.quarterly;
};

const getSubsidiaryTags = (
  isGroup: boolean,
  answer: MetricAnswer,
  metricRow?: AggregatedMetricsTableData
) => {
  if (!isGroup) return [];
  const isTagsValueRow = metricRow?.tagName && !!metricRow.subRows?.length;
  const isLastTagRow = metricRow?.tagName && metricRow.tags?.length && !metricRow.subRows?.length;
  const isMetricUnderTag =
    metricRow?.tags?.length && !metricRow.subRows?.length && !metricRow?.tagName;
  if (isLastTagRow) {
    return getTagsCombination(answer?.materialMetricTags ?? []).allTagsArray.filter((tag) =>
      metricRow.tags?.every((mTag) =>
        tag?.some((tg) => mTag?.tagType === tg.tagType && mTag?.tagValue === tg.tagValue)
      )
    );
  }
  if (isTagsValueRow) {
    return (
      metricRow.subRows?.flatMap((subR) =>
        getTagsCombination(answer?.materialMetricTags ?? []).allTagsArray.filter((tag) =>
          subR.tags?.every((mTag) =>
            tag?.some((tg) => mTag?.tagType === tg.tagType && mTag?.tagValue === tg.tagValue)
          )
        )
      ) ?? []
    );
  }
  if (isMetricUnderTag) {
    return getTagsCombination(answer?.materialMetricTags ?? []).allTagsArray.filter((tag) =>
      metricRow.tags?.every((mTag) =>
        tag?.some((tg) => mTag?.tagType === tg.tagType && mTag?.tagValue === tg.tagValue)
      )
    );
  }
  return getTagsCombination(answer?.materialMetricTags ?? []).allTagsArray ?? [];
};

export const getAggregatedDatapoints = ({
  metric,
  metricRow,
  answers,
  isChild = false,
  tags,
  childrenMetrics,
  filterAnswers = true,
  groupAnswersData,
  currencyConversionData,
  isGroup,
}: {
  metric: AggregatedMetricsTableData['metric'];
  metricRow: AggregatedMetricsTableData | undefined;
  answers: MetricAnswer[];
  isChild?: boolean;
  tags?: {
    tagType: string;
    tagValue: string;
  }[][];
  childrenMetrics?: string[];
  filterAnswers?: boolean;
  groupAnswersData?: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'];
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion'];
  isGroup: boolean;
}): YearObject => {
  if (answers) {
    const chosenAnswers = filterAnswers
      ? getRequiredAnswers(metric, answers, isChild, tags, childrenMetrics)
      : answers;

    const datapoints = chosenAnswers?.flatMap((answer) => {
      const isAnswerYearly = answer?.frequency === FrequencyEnums.yearly;
      const subTags = getSubsidiaryTags(isGroup, answer, metricRow);
      const isCollectedOnGroup = hasChildOnGroupLevel(metric);

      if (answer.hasOptedOut) return [];
      if (answer?.hasTags) {
        return answer?.datapoints
          ?.filter((datapoint) => {
            if (isGroup && !(!isCollectedOnGroup ? subTags : (tags ?? [])).length) return false;
            const isParentMetricWithNoTags =
              !!metricRow?.subRows &&
              !!metricRow.metric?.childrenMetrics?.length &&
              !metricRow.tagName &&
              !metricRow.tagType;
            return combinedFiltersForTags(
              datapoint,
              isAnswerYearly,
              isGroup && !isCollectedOnGroup
                ? subTags
                : isParentMetricWithNoTags
                  ? (getTagsCombination(answer?.materialMetricTags ?? []).allTagsArray ?? [])
                  : (tags ?? [])
            );
          })
          .map((dp) => ({
            ...dp,
            metricRef: answer?.metricRef ?? '',
            tagValues: dp.datapointTags.map((tag) => tag.tagValue),
            answerId: answer.id,
          }));
      }

      return answer?.datapoints
        .filter((datapoint) => combinedFiltersForNoTags(datapoint, isAnswerYearly))
        .map((dp) => ({
          ...dp,
          metricRef: answer?.metricRef ?? '',
          tagValues: dp.datapointTags.map((tag) => tag.tagValue),
          answerId: answer.id,
        }));
    });

    const uniqueDPs = getUniqueDataPoints(datapoints);

    const isMetricYearly = !hasQuarterlyFrequency(metric);

    const isMonetaryMetric =
      metric.unitOfMeasurement === 'currency' ||
      metric.unitOfMeasurement?.includes('Monetary unit') ||
      metric.unitOfMeasurement?.includes('€');

    if (isMetricYearly) {
      const datapointsPerYear = uniqueDPs.map((dp) => ({
        value:
          isMonetaryMetric && isGroup
            ? convertCurrency(dp, groupAnswersData, currencyConversionData)
            : Number(dp.value ?? 0),
        metricRef: dp.metricRef,
        tagValues: dp.datapointTags.map(
          (tag: { tagValue: string; tagType: string }) => tag.tagValue
        ),
      }));
      const total = resolveMetricCalculation(datapointsPerYear, metric.calculation);
      return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: total };
    }

    // Quarterly case
    const totalPerTimeframe = QUARTERS_FIELDS.map((field) => {
      const datapointsPerTimeframe = uniqueDPs
        ?.filter(
          (datapoint) =>
            datapoint?.timeframe === field || datapoint?.timeframe === TimePeriodsEnums.year
        )
        .map((dp) => ({
          value: Number(dp.value ?? 0),
          metricRef: dp.metricRef,
          tagValues: dp.datapointTags.map(
            (tag: { tagValue: string; tagType: string }) => tag.tagValue
          ),
        }));
      const total = resolveMetricCalculation(datapointsPerTimeframe, metric.calculation);

      return { [field]: total };
    });

    const quarters: QuartersObject = Object.assign({}, ...totalPerTimeframe);
    return { Year: addQuarters(quarters), ...quarters };
  }
  // No datapoints case
  return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: 0 };
};

const getParentAnswerTags = (metricRow: AggregatedMetricsTableData) => {
  const hasMultipleTagTypes = metricRow?.subRows?.some((subRow) =>
    subRow.subRows?.some((row) => row.tags?.length)
  );
  const hasSingleTag = metricRow?.subRows?.some((subRow) => subRow.tags?.length);

  if (hasMultipleTagTypes)
    return metricRow?.subRows?.flatMap((row) => row.subRows?.map((r) => r.tags ?? []) ?? []);

  if (hasSingleTag) return metricRow.subRows?.map((row) => row?.tags ?? []);
  return undefined;
};

const getNestedData = (
  metricRow: AggregatedMetricsTableData,
  answers: MetricAnswer[],
  isGroup: boolean,
  groupAnswersData?: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'],
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion']
): AggregatedMetricsTableData => {
  if (!!metricRow?.subRows?.length) {
    return {
      ...metricRow,
      isChild: metricRow.metric.parentMetrics?.length > 0,
      subRows: metricRow.subRows.map((subRow) => {
        if (subRow.tags?.length) {
          if (subRow.subRows?.length) {
            return getNestedData(
              {
                ...subRow,
                result: getAggregatedDatapoints({
                  metric: subRow.metric,
                  metricRow: subRow,
                  answers,
                  groupAnswersData,
                  isChild: subRow?.isChild,
                  tags: [subRow.tags],
                  childrenMetrics: subRow.metric?.childrenMetrics?.length
                    ? getFullMetricWithChildren(subRow.metric).map((m) => m.reference)
                    : [],
                  currencyConversionData,
                  isGroup,
                }),
              },
              answers,
              isGroup,
              groupAnswersData,
              currencyConversionData
            );
          }
          return {
            ...subRow,
            result: getAggregatedDatapoints({
              metric: subRow.metric,
              metricRow: subRow,
              answers,
              groupAnswersData,
              isChild: subRow?.isChild,
              tags: [subRow.tags],
              currencyConversionData,
              isGroup,
            }),
          };
        } else if (!!subRow?.tagType) {
          return getNestedData(
            {
              ...subRow,
              result: getAggregatedDatapoints({
                metric: subRow.metric,
                metricRow: subRow,
                answers,
                isChild: true,
                tags: subRow.metric?.childrenMetrics?.length
                  ? getParentAnswerTags(subRow)
                  : subRow?.subRows?.map((row) => row?.tags ?? []),
                childrenMetrics: subRow.metric?.childrenMetrics?.length
                  ? getFullMetricWithChildren(subRow.metric).map((m) => m.reference)
                  : [],
                groupAnswersData,
                currencyConversionData,
                isGroup,
              }),
            },
            answers,
            isGroup,
            groupAnswersData,
            currencyConversionData
          );
        } else {
          return getNestedData(
            {
              ...subRow,
              result: getAggregatedDatapoints({
                metric: subRow.metric,
                metricRow: subRow,
                answers,
                isChild: true,
                tags: subRow.metric?.childrenMetrics?.length
                  ? getParentAnswerTags(subRow)
                  : subRow?.subRows?.map((row) => row?.tags ?? []),
                childrenMetrics: subRow.metric?.childrenMetrics?.length
                  ? getFullMetricWithChildren(subRow.metric).map((m) => m.reference)
                  : [],
                isGroup,
                currencyConversionData,
                groupAnswersData,
              }),
            },
            answers,
            isGroup,
            groupAnswersData,
            currencyConversionData
          );
        }
      }),
      result:
        metricRow?.result ??
        getAggregatedDatapoints({
          metric: metricRow.metric,
          metricRow: metricRow,
          answers,
          isChild: metricRow.metric.parentMetrics?.length > 0,
          childrenMetrics: metricRow.metric?.childrenMetrics?.length
            ? getFullMetricWithChildren(metricRow.metric).map((m) => m.reference)
            : [],
          tags: getParentAnswerTags(metricRow),
          groupAnswersData,
          currencyConversionData,
          isGroup,
        }),
    };
  } else {
    return {
      ...metricRow,
      result:
        metricRow.result ??
        getAggregatedDatapoints({
          metric: metricRow.metric,
          metricRow: metricRow,
          answers,
          isChild: metricRow.isChild,
          tags: metricRow?.tags?.length ? [metricRow?.tags] : undefined,
          groupAnswersData,
          currencyConversionData,
          isGroup,
        }),
    };
  }
};

export const getAggregatedMetricAnswers = (
  metric: AggregatedMetricsTableData,
  isGroup: boolean,
  answers?: MetricAnswer[],
  groupAnswersData?: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'],
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion']
) => {
  if (answers) {
    return getNestedData(metric, answers, isGroup, groupAnswersData, currencyConversionData);
  }
  return metric;
};

export const filterAggregatedMetricLevels = (metrics: AggregatedMetricsTableData[]) => {
  const isChildOfNarrative = (metric: AggregatedMetricsTableData['metric']): boolean => {
    if (metric.parentMetrics.length) {
      return metric.parentMetrics.every((met) => {
        const parentMetric = metrics.find((m) => m.metric.reference === met.parentMetricRef);
        if (parentMetric) return isChildOfNarrative(parentMetric.metric);
      });
    }
    return metric.metricType !== QuestionType_Enum_.Decimal_;
  };
  return [
    ...metrics.filter(
      (m) => m.metric.metricType === QuestionType_Enum_.Decimal_ && !isChildOfNarrative(m.metric)
    ),
    ...filterAggregatedChildrenInFirstLevelMetrics(
      metrics.filter((m) => m.metric.metricType !== QuestionType_Enum_.Decimal_)
    ),
  ];
};

export const filterMetricLevels = (metrics: AssessableMetrics, companyStandardId: string) => {
  const isChildOfNarrative = (metric: AggregatedMetricsTableData['metric']): boolean => {
    if (metric.parentMetrics.length) {
      return metric.parentMetrics.every((met) => {
        const parentMetric = metrics.find((m) => m.reference === met.parentMetricRef);
        if (parentMetric) return isChildOfNarrative(parentMetric);
      });
    }
    return metric.metricType !== QuestionType_Enum_.Decimal_;
  };
  return [
    ...metrics.filter(
      (m) => m.metricType === QuestionType_Enum_.Decimal_ && !isChildOfNarrative(m)
    ),
    ...filterChildrenInFirstLevelMetrics(
      metrics.filter((m) => m.metricType !== QuestionType_Enum_.Decimal_),
      companyStandardId
    ),
  ];
};

const formatMetricAnswerObject = (
  materialMetric:
    | { frequency: string; materialMetricTags: MaterialMetric['materialMetricTags'] }
    | undefined,
  answers: ReportingUnitType['answers'] | undefined,
  metricRef: string,
  parentMaterialMetric:
    | { frequency: string; materialMetricTags: { [key: string]: any }[] }
    | undefined
) => {
  const frequency = materialMetric?.frequency;
  const hasTags =
    !!materialMetric?.materialMetricTags?.length ||
    !!parentMaterialMetric?.materialMetricTags.flatMap((mTag) => mTag.materialTagValues).length;
  const foundAnswer = answers?.find((answer) => answer.metricRef === metricRef);
  const materialMetricTags = materialMetric?.materialMetricTags;
  const parentMaterialMetricTags = parentMaterialMetric?.materialMetricTags;

  if (foundAnswer)
    return {
      ...foundAnswer,
      frequency: frequency,
      hasTags: hasTags,
      materialMetricTags: !!parentMaterialMetricTags?.length
        ? parentMaterialMetricTags
        : materialMetricTags,
    };
  return undefined;
};

export const getMetricAnswers = (data: {
  metric: MetricsTableData['metric'];
  materialStandardId: string;
  isGroup: boolean;
  companyAnswersData: NonNullable<
    GetAnswersForMetricsOnCompanyLevelQuery_['EsrsAssessment_by_pk']
  >['reportingUnits'];
  groupAnswersData: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'];
  standardRef?: string;
}) => {
  const { metric, materialStandardId, isGroup, companyAnswersData, groupAnswersData, standardRef } =
    data;
  const requiredMetrics = getFullMetricWithChildren(metric);

  const metricAnswers = requiredMetrics.flatMap((requiredMetric) => {
    const materialMetric = requiredMetric.materialMetrics?.find(
      (mm) => mm.materialStandardId === materialStandardId
    );
    const parentMaterialMetric = requiredMetrics
      .find((m) =>
        m.childrenMetrics?.some((cm) => cm.childMetric?.reference === requiredMetric.reference)
      )
      ?.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId);

    if (
      materialMetric?.dataCollection === DataCollectionLevel.group ||
      materialMetric?.dataCollection === DataCollectionLevel.company
    ) {
      const reportingUnit = companyAnswersData?.find((ru) => ru.isCompanyLevel);
      return formatMetricAnswerObject(
        materialMetric,
        reportingUnit?.answers,
        requiredMetric.reference,
        parentMaterialMetric
      );
    } else if (isGroup) {
      return groupAnswersData.flatMap((subsidiary) => {
        const currentStandard = subsidiary.materialStandards?.find(
          (standard) => standard.standardRef === standardRef
        );
        const subMaterialMetric = currentStandard?.materialMetrics?.find(
          (mm) => mm.metricRef === requiredMetric?.reference
        );
        const reportingUnits = subsidiary.reportingUnits?.filter((ru) =>
          subMaterialMetric?.dataCollection === DataCollectionLevel.company
            ? ru.isCompanyLevel
            : !ru.isCompanyLevel
        );

        const subParentMaterialMetric = currentStandard?.materialMetrics?.find(
          (mm) => mm.metricRef === parentMaterialMetric?.metric.reference
        );
        return reportingUnits?.map((reportingUnit) => {
          return formatMetricAnswerObject(
            subMaterialMetric,
            reportingUnit?.answers,
            requiredMetric.reference,
            subParentMaterialMetric
          );
        });
      });
    } else {
      const reportingUnits = companyAnswersData?.filter((ru) => !ru.isCompanyLevel);
      return reportingUnits?.map((reportingUnit) => {
        return formatMetricAnswerObject(
          materialMetric,
          reportingUnit?.answers,
          requiredMetric.reference,
          parentMaterialMetric
        );
      });
    }
  });

  return (metricAnswers?.filter((a) => a !== undefined) ?? []) as MetricAnswer[];
};

export const useGetAggregatedMetrics = (
  metrics: NonNullable<GetMetricsDrQuery_['DisclosureRequirement_by_pk']>['metrics'],
  standardRef: string,
  materialStandardId: string,
  companyAnswersData: NonNullable<
    GetAnswersForMetricsOnCompanyLevelQuery_['EsrsAssessment_by_pk']
  >['reportingUnits'],
  groupAnswersData: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'],
  isGroup: boolean
) => {
  const { esrsAssessmentId = '' } = useParams();
  const filteredMetrics = useMemo(
    () => separateQualitativeMetricsFromQuantitativeParents(metrics),
    [metrics]
  );

  const allMetrics = useMemo(() => filteredMetrics ?? [], [filteredMetrics]);
  const { data: currencyConversionData } = useGetAssessmentCurrencyConversionQuery({
    fetchPolicy: 'network-only',
    variables: {
      assessmentId: esrsAssessmentId,
    },
    skip: !isGroup,
  });

  const qualitativeMetrics = useMemo(
    () =>
      filteredMetrics.filter(
        (metric) =>
          metric.metricType === QuestionType_Enum_.LongText_ ||
          metric.metricType === QuestionType_Enum_.YesNo_ ||
          metric.metricType === QuestionType_Enum_.SingleChoice_ ||
          metric.metricType === QuestionType_Enum_.MultipleChoice_
      ) ?? [],
    [filteredMetrics]
  );

  const quantitativeMetricsWithTags = useMemo(
    () =>
      allMetrics.map((metric) =>
        !isFullMetricOnSubLevel(metric)
          ? metric
          : addMaterialMetricTags(metric, groupAnswersData, standardRef)
      ),
    [allMetrics, groupAnswersData, standardRef]
  );

  const nestedMetrics = useMemo(
    () =>
      allMetrics.map((metric) => {
        return getNestedRows({ metric, materialStandardId, isGroup });
      }),
    [allMetrics]
  );

  const allAggregatedMetrics = useMemo(() => {
    if (isGroup) {
      const groupNestedMetrics = quantitativeMetricsWithTags.map((metric) => {
        const materialMetrics = groupAnswersData?.flatMap((company) =>
          company.materialStandards
            .find((ms) => ms.standardRef === standardRef)
            ?.materialMetrics.find((mm) => mm?.metricRef === metric.reference)
        );
        return getNestedRows({
          metric: metric as AssessableMetrics[number],
          materialStandardId,
          materialMetrics: materialMetrics as GroupMaterialMetrics,
          isGroup,
        });
      });
      const groupMetricAnswers = groupNestedMetrics?.map(({ metric }) => {
        const metricAnswers = getMetricAnswers({
          metric,
          materialStandardId: materialStandardId,
          isGroup: true,
          companyAnswersData,
          groupAnswersData,
          standardRef,
        });
        return { [metric.reference]: metricAnswers };
      });

      const results = groupNestedMetrics.map((m) =>
        getAggregatedMetricAnswers(
          m,
          isGroup,
          groupMetricAnswers.find((answer) => !!answer?.[m.metric.reference])?.[m.metric.reference],
          groupAnswersData,
          currencyConversionData?.esrs_AssessmentCurrencyConversion
        )
      );
      return results;
    } else {
      const companyMetricAnswers = nestedMetrics?.map(({ metric }) => {
        const metricAnswers = getMetricAnswers({
          metric,
          materialStandardId,
          isGroup: false,
          companyAnswersData,
          groupAnswersData,
        });
        return { [metric.reference]: metricAnswers };
      });

      const results = nestedMetrics.map((m) =>
        getAggregatedMetricAnswers(
          m,
          isGroup,
          companyMetricAnswers.find((answer) => !!answer?.[m.metric.reference])?.[
            m.metric.reference
          ]
        )
      );

      return results;
    }
  }, [companyAnswersData, groupAnswersData, isGroup, nestedMetrics]);

  const allMetricsWithChildren: AssessableMetrics = useMemo(() => {
    const metricsWithChildren = [
      ...filteredMetrics,
      ...filteredMetrics.flatMap((metric) => {
        const children = metric?.childrenMetrics ?? [];

        return children.flatMap(({ childMetric }) => {
          const childMetrics = childMetric?.childrenMetrics ?? [];

          return [
            {
              ...childMetric,
              materialMetrics: childMetric?.materialMetrics,
            },
            ...childMetrics.map((child) => ({
              ...child.childMetric,
              materialMetrics: child.childMetric?.materialMetrics,
            })),
          ];
        });
      }),
    ];

    return metricsWithChildren.filter((m) => m !== undefined) as AssessableMetrics;
  }, [filteredMetrics]);

  const aggregatedMetricsAnswers: AggregatedQualitativeAnswers | undefined = useMemo(
    () =>
      mapMetricsWithAnswers({
        metrics: allMetricsWithChildren,
        isGroup,
        companyAnswersData,
        groupAnswersData,
        companyStandardId: materialStandardId,
      }),
    [allMetricsWithChildren, groupAnswersData, companyAnswersData, isGroup, materialStandardId]
  );

  return {
    aggregatedMetrics: filterAggregatedMetricLevels(allAggregatedMetrics),
    allAggregatedMetrics,
    qualitativeMetrics,
    aggregatedMetricsAnswers,
    aggregationQuantitativeMetrics: allMetrics,
  };
};

export const getGraphObject = (object: AggregatedMetricsTableData) => {
  const isYearly = object.metric.materialMetrics?.[0]?.frequency === FrequencyEnums.yearly;
  if (isYearly) {
    return object.result ?? ({} as QuartersObject);
  } else {
    const result: QuartersObject & { Year?: number } = object.result ?? ({} as QuartersObject);
    delete result.Year;
    return result;
  }
};

export const getNestedMetrics = (metric: MetricsTableData) => {
  const allMetrics: MetricsTableData[] = [metric];

  const recurseChildren = (metric: MetricsTableData) => {
    metric?.subRows?.forEach((subRow) => {
      if (!(subRow.tagName && subRow.tags && !subRow.isChild)) {
        allMetrics.push(subRow);
        recurseChildren(subRow);
      }
    });
  };

  allMetrics.forEach((metric) => recurseChildren(metric));

  return allMetrics;
};

export const hasChildOnSubLevel = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.some(
      (child) => child?.childMetric && hasChildOnSubLevel(child?.childMetric)
    );
  }
  return materialMetric?.dataCollection === DataCollectionLevel.subsidiaries;
};

export const hasChildOnBULevel = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.some(
      (child) => child.childMetric && hasChildOnBULevel(child.childMetric)
    );
  }
  return materialMetric?.dataCollection === DataCollectionLevel.reportingUnits;
};

export const isFullMetricOnBULevel = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.every(
      (child) => child.childMetric && isFullMetricOnBULevel(child.childMetric)
    );
  }
  return materialMetric?.dataCollection === DataCollectionLevel.reportingUnits;
};

export const isFullMetricOnSubLevel = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.every(
      (child) => child.childMetric && isFullMetricOnSubLevel(child.childMetric)
    );
  }
  return materialMetric?.dataCollection === DataCollectionLevel.subsidiaries;
};

export const isFullMetricQuarterlyLevel = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.every(
      (child) => child.childMetric && isFullMetricQuarterlyLevel(child.childMetric)
    );
  }
  return materialMetric?.frequency === FrequencyEnums.quarterly;
};

export const isFullParentMetricOnSubLevel = (
  metric: MaybeHasChild,
  parentStandardId: string,
  requiredMaterialMetrics: GetRequiredMaterialMetricsQuery_['esrs_MaterialMetric']
): boolean => {
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.every(
      (child) =>
        child.childMetric &&
        isFullParentMetricOnSubLevel(child.childMetric, parentStandardId, requiredMaterialMetrics)
    );
  }
  return (
    requiredMaterialMetrics?.find(
      (mm) => mm.materialStandardId === parentStandardId && mm.metric.reference === metric.reference
    )?.dataCollection === DataCollectionLevel.subsidiaries
  );
};

export const hasChildOnGroupLevel = (metric: MaybeHasChild): boolean => {
  const materialMetric = metric.materialMetrics?.[0];
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.some(
      (child) => child?.childMetric && hasChildOnGroupLevel(child?.childMetric)
    );
  }
  return materialMetric?.dataCollection === DataCollectionLevel.group;
};

export const processNarrativeMetrics = (
  metric: AggregatedMetricsTableData,
  answers: AggregatedQualitativeAnswers
): AggregatedMetricsTableData => {
  const answer = answers.find((met) => met.metricRef === metric.metric.reference);

  if (metric.metric.metricType === QuestionType_Enum_.Decimal_) return metric;

  const unlockTerm =
    metric.metric.unlockCondition === null
      ? ''
      : metric.metric.unlockCondition
        ? BooleanEnum.False
        : BooleanEnum.True;

  if (metric.metric.metricType === QuestionType_Enum_.YesNo_) {
    const hasUnlockCondition = metric.metric.unlockCondition !== null;
    const hasAnswer = answer?.answer?.datapoints.some(
      (dp) => dp.factValue !== null && dp.factValue !== ''
    );

    if (
      answer?.answer?.datapoints.some((dp) => dp.factValue === unlockTerm && dp.factValue !== '') ||
      (!hasAnswer && hasUnlockCondition)
    ) {
      return {
        ...metric,
        subRows: metric.subRows
          ? [
              {
                ...metric.subRows?.[0],
                locked: true,
                lockedCount: metric.subRows?.length,
                subRows: [],
              },
            ]
          : [],
      };
    }
    return {
      ...metric,
      subRows: metric.subRows ? metric.subRows.map((m) => processNarrativeMetrics(m, answers)) : [],
    };
  }

  const metadata = answer?.answer?.datapoints.find(
    (dp) => dp.timeframe === TimePeriodsEnums.year
  )?.metadata;

  const showButton =
    metric.metric.childrenMetrics.length &&
    (!metadata || metadata?.before?.isVisible === false || metadata?.after?.isVisible === false);

  return {
    ...metric,
    subRows: metric.subRows
      ? [
          ...(metadata?.before?.isVisible === true
            ? [
                {
                  ...metric,
                  subRows: [],
                  isAdditionalInfo: true,
                  additionalInfoPlacement: 'before',
                },
              ]
            : []),
          ...metric.subRows.map((m) => processNarrativeMetrics(m, answers)),
          ...(metadata?.after?.isVisible === true
            ? [
                {
                  ...metric,
                  subRows: [],
                  isAdditionalInfo: true,
                  additionalInfoPlacement: 'after',
                },
              ]
            : []),
          ...(showButton ? [{ ...metric, subRows: [], isAdditionalInfoButton: true }] : []),
        ]
      : [],
  };
};
